<template>
  <v-app id="login" class="secondary">
    <v-main>
      <v-container class="pa-0" fluid fill-height cl
        ><div class="overlay-filter"></div>
        <v-row align-center no-gutters style="height: 50vh">
          <v-col style="height: 50vh">
            <v-card
              style="height: 50vh"
              class="pa-0 d-flex justify-center align-center"
              tile
              ><div>
                <view-image
                  style="width: 400px"
                  :contain="false"
                  defaultImage="login"
                  aspect-ratio="1.5"
                  :image="imageUrl"
                ></view-image>
              </div>
            </v-card>
          </v-col>
          <v-col style="height: 50vh">
            <v-card style="height: 50vh" class="pa-0" tile>
              <v-img
                height="50vh"
                style="width: 50vw"
                src="../../assets/images/login_icons/qp_logo.png"
              ></v-img>
            </v-card>
          </v-col>
        </v-row>
        <v-row align-center no-gutters style="height: 50vh">
          <v-col style="height: 50vh">
            <v-card style="height: 50vh" class="pa-0" tile>
              <v-img
                height="50vh"
                style="width: 50vw"
                src="../../assets/images/login_icons/qp_logo.png"
              ></v-img>
            </v-card>
          </v-col>
          <v-col style="height: 50vh">
            <v-card
              style="height: 50vh"
              class="pa-0 d-flex justify-center align-center"
              tile
              ><div>
                <view-image
                  style="width: 400px"
                  :contain="false"
                  defaultImage="login"
                  aspect-ratio="1.5"
                  :image="imageUrl"
                ></view-image>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-layout align-center justify-center class="login_bg floating-login">
          <v-flex style="width: 375px">
            <!-- <h1 class="flex my-4 white-color-txt">QPortal</h1> -->
            <v-card class="elevation-1 pa-3" style="min-height: 200px">
              <v-card-text>
                <div class="layout column align-center"></div>

                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  @submit="signIn"
                  method="post"
                >
                  <v-text-field
                    prepend-inner-icon="mdi-account"
                    name="login"
                    outlined
                    placeholder="Login"
                    ref="emailRef"
                    type="text"
                    v-model="user.username"
                    :rules="[(v) => !!v || 'Username is required']"
                  />

                  <v-text-field
                    :type="hidePassword ? 'password' : 'text'"
                    :prepend-inner-icon="
                      hidePassword ? 'mdi-eye-off' : 'mdi-eye'
                    "
                    name="password"
                    outlined
                    placeholder="Password"
                    id="password"
                    ref="passRef"
                    :rules="[(v) => !!v || 'Password is required']"
                    v-model="user.password"
                    @click:prepend-inner="hidePassword = !hidePassword"
                  />
                  <!-- <v-divider></v-divider> -->
                  <v-row>
                    <!-- <v-spacer></v-spacer> -->
                    <v-col>
                      <v-btn
                        type="submit"
                        block
                        color="login-color"
                        @click="signIn"
                        :loading="loading"
                        >SIGN IN</v-btn
                      >
                    </v-col>

                    <!-- <v-spacer></v-spacer> -->
                  </v-row>
                </v-form>
                <v-row v-if="conf || conf != ''">
                  <v-col>
                    <v-btn
                      block
                      color="login-color"
                      @click="signInAD"
                      :loading="loading"
                      >SIGN IN with Azure
                      <v-icon right dark>
                        mdi-microsoft-azure
                      </v-icon></v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import ViewImage from "../../components/Image/ViewImage.vue";
import { msalInstance } from "vue-msal-browser";
import { default as msalPlugin } from "vue-msal-browser";
import Vue from "vue";
export default {
  components: { ViewImage },
  data() {
    return {
      loading: false,
      valid: true,
      hidePassword: true,
      user: {},
      imageUrl: null,
      account: undefined,
      conf: null,
    };
  },
  mounted() {
    this.getConfiguration();
  },
  beforeCreate() {
    let token = localStorage.getItem("token");
    if (token) {
      this.$store
        .dispatch("loadPermissions")
        .then((res) => {
          if (res.status == 200) {
            this.findFirstPage();
          } else {
            this.$store.commit("logout");
          }
        })
        .catch((err) => {
          if (err) {
            this.$store.commit("logout");
          }
        });
    } else {
      this.$store.commit("logout");
    }
    this.$http
      .get("/logo")
      .then((response) => {
        if (response.status == 200 && response.data.data) {
          this.imageUrl = response.data.data.logo;
        }
      })
      .catch((error) => {
        this.errorChecker(error);
      });
  },
  methods: {
    getConfiguration() {
      this.$http
        .get("sso-config")
        .then((response) => {
          this.conf = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // async signInADNew() {
    //   console.log("new");

    //   try {
    //     // let existingTokenResponse = getters.mainTokenResponse;
    //     let newTokenResponse;

    //     // The user has already logged in. We try to get his token silently
    //     // if (existingTokenResponse)
    //     //   newTokenResponse = await msalInstance.acquireTokenSilent({
    //     //     account: existingTokenResponse.account,
    //     //     scopes: msalInstance.extendedConfiguration.auth.scopes,
    //     //   });
    //     // The user has not logged in. We check if he comes back from a redirect with a token
    //     // else
    //     newTokenResponse = await msalInstance.handleRedirectPromise();
    //     console.log(newTokenResponse);
    //     // No token found, we redirect the user
    //     if (!newTokenResponse) {
    //       await msalInstance.loginRedirect(msalInstance.loginRequest);
    //       return false;
    //     }
    //     // There is an existing token, we authentify the user
    //     else if (newTokenResponse) {
    //       // console.log(newTokenResponse);
    //       // We add the access token as an authorization header for our Axios requests to our API
    //       this._vm.axios.defaults.headers.common["Authorization"] =
    //         "Bearer " + newTokenResponse.accessToken;
    //       // if (msalInstance.extendedConfiguration.graph) {
    //       //   console.log("yes graph");
    //       //   let graph = await msalInstance.getSilentToken(
    //       //     newTokenResponse.account,
    //       //     msalInstance.extendedConfiguration.graph.scopes
    //       //   );
    //       //   localStorage.setItem("response from gateway", graph);
    //       //   console.log(graph);

    //       //   // The graph is set, we check if the user has already a picture in the local storage
    //       //   // if he does not we grab a token silently for our graph scope and call Microsoft graph to get the picture
    //       //   // if (!localStorage.getItem("userPicture")) {
    //       //   //   let graphTokenResponse = await msalInstance.getSilentToken(
    //       //   //     newTokenResponse.account,
    //       //   //     msalInstance.extendedConfiguration.graph.scopes
    //       //   //   );
    //       //   // let graphResponse = await msalInstance.callMSGraph(
    //       //   //   msalInstance.extendedConfiguration.graph.url,
    //       //   //   graphTokenResponse.accessToken
    //       //   // );
    //       //   // dispatch("AzureSetPicture", graphResponse);
    //       // }
    //     }
    //     return true;
    //     // }
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
    async signInAD() {
      // sessionStorage.clear();
      Vue.use(msalPlugin, this.conf);
      const isCookieEnabled = navigator.cookieEnabled;
      if (!isCookieEnabled) {
        this.showError("Please enable cookies");
        return;
      }
      let accounts = msalInstance.getAllAccounts();
      if (accounts[0]) {
        var request = {
          scopes: ["openid", "profile"],
          loginHint: accounts[0].username, // For v1 endpoints, use upn from idToken claims
        };
        let response = await msalInstance
          .getSilentToken(accounts[0])
          .catch((error) => {
            console.log("Token expired");
            console.log(error);
            return msalInstance.acquireTokenPopup(request);
          });
        this.user = response?.idTokenClaims;
        if (this.user) {
          this.$router.push({ name: "LoginAD" });
        }
      } else {
        await this.$msal.authenticateRedirect();
        // await msalInstance.handleRedirectPromise();

        // const accounts = msalInstance.getAllAccounts();
        // if (accounts.length === 0) {
        //   // No user signed in
        //   msalInstance.loginRedirect();
        // }
        // await msalInstance
        //   .handleRedirectPromise()
        //   .then((tokenResponse) => {
        //     console.log("done");
        //     console.log(tokenResponse);
        //     if (!tokenResponse) {
        //       console.log("no token");
        //       // return msalInstance.acquireTokenRedirect(request);
        //       const accounts = msalInstance.getAllAccounts();
        //       if (accounts.length === 0) {
        //         // No user signed in
        //         msalInstance.loginRedirect();
        //       }
        //     } else {
        //       // msalInstance.loginRedirect();
        //       console.log(tokenResponse);
        //       // Do something with the tokenResponse
        //     }
        //   })
        //   .catch((err) => {
        //     // Handle error
        //     console.error(err);
        //   });
        // await this.$msal.authenticateRedirect();
      }
    },
    signIn() {
      event.preventDefault();
      if (!this.$refs.form.validate()) {
        this.showError("Please fill email and password");
        return;
      }
      this.showLoader("Checking..");
      this.$store
        .dispatch("login", this.user)
        .then((res) => {
          if (res.status == 200 && res.data.status) {
            this.hideLoader();
            this.showSuccess("Authentication Success.");
            this.findFirstPage();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
  },
};
</script>
<style scoped>
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}
.v-main {
  background-color: #333;
}
.v-card {
  display: block;
  border-radius: 5px;
}
.secondary {
  background-color: #000000 !important;
  border-color: #000000 !important;
}
/* .login_bg {
  background-image: url("../../assets/images/krews/loginbg.png");
  background-size: unset;
  background-position: center center;
} */

.login-color {
  font-size: 18px;
  color: #fff !important;
  line-height: 1.2;
  text-transform: uppercase;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 100%;
  height: 42px;
  border-radius: 4px;
  background-color: #052b47 !important;
  /* background: rgb(164, 69, 178);
  background: linear-gradient(
    90deg,
    rgba(164, 69, 178, 1) 0%,
    rgba(212, 24, 114, 1) 52%,
    rgba(250, 66, 153, 1) 100%
  ); */
  position: relative;
  z-index: 1;
  /* -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s; */
}
.teal-color-txt {
  color: #00b0af;
}
.white-color-txt {
  color: #fff;
  text-align: center;
}
.overlay-filter {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}
.floating-login {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  margin-top: -130px;
  margin-left: -185px;
  z-index: 3;
}
</style>
